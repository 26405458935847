@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.loginMainDashboard {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%);
}

.loginDivDashBoard {
    width: 100%;
    height: auto;
    min-height: 80vh;
    max-width: 1222px;
}

.loginNavDash {
    width: 50%;
    height: 100px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 12px;
}

.loginNavDash img {
    width: 100px;
    height: auto;
}

.loginNavDash span {
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    color: black;
    font-weight: 600;
}

.loginInputsDashb {
    width: 100%;
    height: auto;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.loginInputsDashb h2 {
    font-size: 41px;
    font-family: "Poppins", sans-serif;
}

.loginInputsDashb div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginInputsDashb div input {
    width: 40%;
    max-width: 250px;
    font-size: 18px;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    border-bottom: 2px solid black;
    outline: none;
    padding-left: 20px;
}

.loginBtnDash {
    width: 120px;
    height: 70px;
    border-radius: 12px;
    border: none;
    padding: 15px;
    font-size: 21px;
    cursor: pointer;
    background-color: #e5a825;
    color: white;
    font-weight: 600;
}

.homeBtnDash {
    font-size: 25px;
    position: absolute;
    top: 100px;
    right: 10vw;
}