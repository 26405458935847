@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

/* Scroll bar 👇🏻 */
/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track { */
/* box-shadow: inset 0 0 5px grey;  */
/* border-radius: 10px; */
/* background-color: rgba(0, 0, 0, 0) !important; */
/* } */

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e38b06;
    /* linear-gradient(180deg, rgba(211,212,226,1) 0%, rgba(105,196,210,1) 100%) */
    /* background: rgb(60, 60, 193); */
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e38b06;
}