.menuSlideMain {
    width: 350px;
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
    color: black;
    position: fixed;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 4;
    transition: 0.3s ease-in-out;
}

.menuSlideMainShow {
    width: 350px;
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
    color: black;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 4;
    transition: 0.3s ease-in-out;
}

.menuSlideOne {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* border-bottom: 1px solid grey; */
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 0px 0px;
}

.menuSlideOne input {
    width: 70%;
    height: auto;
    padding: 10px;
    font-size: 16px;
    outline: none;
    color: black;
    border: none;
}

.menuSlideOne i {
    font-size: 21px;
    color: black;
    cursor: pointer;
}

.menuSlideOne input::placeholder {
    color: rgb(0, 0, 0);
    opacity: 1;
    font-weight: 400;
    /* Firefox */
}

.menuSlideTwo {
    width: 100%;
    height: auto;
}

.slideTwoBtn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.slideTwoBtn span {
    font-weight: 600;
    color: grey;
    width: 50%;
    text-align: center;
    border-bottom: 1.5px solid rgb(209, 209, 209);
    /* border-bottom: 2px solid rgb(60,60,193); */
    padding: 20px 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
}

.slideTwoBtn span:hover {
    color: black;
    border-bottom: 2px solid rgb(60, 60, 193);
}

.slideTwoBtn span:focus {
    color: black;
    border-bottom: 2px solid rgb(60, 60, 193);
}

.slideTwoBtn span:active {
    color: black;
    border-bottom: 2px solid rgb(60, 60, 193);
}

.slideTwoBtn2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.slideTwoBtn2 span {
    width: 100%;
    border-bottom: 1.5px solid rgb(209, 209, 209);
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
}

.slideTwoBtn2 span i {
    margin-right: 10px;
    cursor: pointer;
}

/* Mobile menu slider */

.menuBurgerIcn{
    font-size: 23px;
    cursor: pointer;
    position: absolute;
    top: 80px;
    left: 40px;
}

.menuCrossIcon{
    font-size: 23px;
    cursor: pointer;
    position: fixed;
    top: 25px;
    left: 10px;
    z-index: 5;
    transition: 1s ease-in;
}

.transDiv{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

@media only screen and (max-width: 650px) {
    .menuBurgerIcn{
        left: 30px;
    }
}

@media only screen and (max-width: 400px) {
    .menuBurgerIcn{
        left: 23px;
    }
}