@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.loginMain {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.purpleMoon {
    width: 120vw;
    height: 100vh;
    position: absolute;
    bottom: -60%;
    z-index: -3;
    background: linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 35px solid white;
    border-radius: 50%;
    margin: 0 auto;
}

.loginDiv {
    width: 85vw;
    max-width: 1100px;
    height: 75vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginSub1 {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.loginGraphics {
    width: 80%;
    height: auto;
    position: relative;
    top: -10px;
}

.loginInputs {
    width: 100%;
    max-width: 500px;
    height: auto;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    top: -10px;
}

.loginInputs input {
    width: 70%;
    height: auto;
    padding: 10px;
    border: none;
    border-bottom: 2px solid rgb(193, 158, 60);
    border-radius: 0px 0px 5px 5px;
    outline: none;
    font-size: 15px;
}

.loginBtn {
    width: 100px;
    height: auto;
    padding: 12px 10px;
    background: rgb(246, 200, 75);
    color: white;
    font-weight: 600;
    border-radius: 12px;
    border: none;
    font-size: 17px;
    cursor: pointer;
}

.loginBtn:hover {
    background: rgb(193, 158, 60);
}

.loginSub2 {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginNav {
    width: 100%;
    height: auto;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.loginNav i {
    color: rgb(60, 60, 193);
    font-size: 25px;
    position: absolute;
    right: 3vw;
    top: 5vw;
    display: none;
}

.loginNav2 {
    width: 100%;
    height: auto;
    min-height: 100px;
    /* float: left; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logoLogin {
    width: 80px;
    height: auto;
    margin-left: 30px;
}

.loginNav span {
    color: rgb(49, 49, 49);
    font-size: 25px;
    text-decoration: none;
    position: relative;
    left: 10px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.loginNav2 i {
    color: rgb(60, 60, 193);
    font-size: 25px;
    position: absolute;
    right: 40px;
    top: 5vh;
}

@media screen and (max-width: 802px) {
    .loginSub2 {
        display: none;
    }

    .loginNav i {
        display: block;
    }
}