.cartViewMain {
    width: 100vw;
    height: auto;
    min-height: 100vh;
}

.cartViewSub {
    width: 100%;
    max-width: 1222px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cartviewBox {
    width: 95%;
    max-width: 600px;
    margin-top: 10px;
    display: flex;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.trashBtnCart {
    color: black;
    position: relative;
    right: -70px;
    background: transparent !important;
    cursor: pointer;
}

.imgCartView{
    width: 200px;
    height: auto;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    border-radius: 10px;
}

@media screen and (max-width: 700px) {
    .trashBtnCart {
        color: black;
        position: absolute;
        left: 85%;
        background: transparent !important;
        cursor: pointer;
    }
}

.checkoutView {
    width: 100vw;
    height: 150px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.subTotalDivCart {
    width: 100%;
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.subTotalDivCart p {
    font-size: 21px;
    color: rgb(20, 20, 20);
    margin: 0px 25px;
    font-weight: 600;
}

.subTotalDivCart span {
    margin: 0px 25px;
    font-size: 21px;
}

.checkoutBtnCart {
    width: 85%;
    max-width: 200px;
    height: auto;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 7px;
    color: white;
    background: rgb(48, 72, 182);
    border: none;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    margin: 20px auto;
}