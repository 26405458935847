@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.aboutMain {
    width: 100vw;
    height: auto;
    min-height: 95vh;
    font-family: "Poppins", sans-serif;
    background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%);
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    height: auto;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.section1Mainmost {
    width: 100vw;
    height: auto;
    min-height: 110vh;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    /* background: hsla(55, 90%, 67%, 1);

background: linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#f7ea60", endColorstr="#FAF289", GradientType=1 ); */
}

.isoImg {
    width: 150px;
    height: auto;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.secOneMain {
    width: 100vw;
    height: auto;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.secOneSub {
    width: 100vw;
    max-width: 1222px;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    /* margin: 5px auto; */
    margin-top: 40px;
}

.bannerImg {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.carouselSideHome {
    width: 90vw;
    height: auto;
    min-height: 70vh;
    border: 1px solid black;
    border-radius: 12px;
}

/* .carouselSide1 {
    width: 100% !important;
    height: auto;
    min-height: 70vh !important;
    border-radius: 12px;
    background: url("../components/images/hpz\ banner.png") no-repeat center center;
    background-size: cover;
    background-blend-mode: multiply;
} */

.topBarOne p {
    margin-top: 10px;
    display: flex;
}

/* top blue bar */
.topBarMain {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBar {
    width: 100%;
    max-width: 1222px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topBarTwo i {
    padding: 10px;
    cursor: pointer;
}

.topBarTwo i:hover {
    color: rgb(255, 255, 255);
}

/* Navbar */

.canopyNav {
    width: 105vw;
    max-width: 1245px;
    position: absolute;
    top: 45px;
    margin-right: 5px;
    /* margin: 0 auto; */
}

.navbarMain {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navBar {
    background-color: white;
    width: 100%;
    max-width: 1222px;
    height: auto;
    min-height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 15px;
}

.logoNav {
    max-width: 150px;
    height: auto;
    cursor: pointer;
}

.logoMaintext {
    color: rgb(49, 49, 49);
    font-size: 24px;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.sidebar {
    position: static !important;
}

.navAccBox {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 20px;
}

.navAccBox i {
    font-size: 21px;
    cursor: pointer;
}

.superTxt {
    position: relative;
    top: -10px;
    left: -10px;
    font-size: 10px;
    background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%);
    color: white;
    padding: 2px 7px;
    border-radius: 50%;
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.userIcon {
    margin-right: 15px;
}

.partOneThree {
    width: 100vw;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: white; */
    font-family: "Poppins", sans-serif;
}

.toggleDiv {
    width: 100vw;
    max-width: 1080px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.toggleDiv div p {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 2px 10px;
    color: #ffffff !important;
    /* border: 1px solid black; */
    /* text-shadow: 1px 1px #000000; */
    /* text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black; */
    /* border-right: 2px solid black; */
    /* border-left: 2px solid black; */
    /* border-bottom: 2px solid black; */
    padding: 3px 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: rgb(206, 53, 53);
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    position: relative;
    top: 2px;
    /* text-transform: uppercase; */
    /* padding-bottom: 10px; */
}

/* Dropdown css */
.dropdown:hover .dropdown-menu {
    display: block !important;
    /* remove the gap so it doesn't close */
    margin-left: 5px;
}

.dropdown-item {
    color: rgb(0, 0, 0) !important;
}


/* Media frames */


@media only screen and (max-width: 1300px) {
    .topBar {
        width: 95%;
    }

    .navBar {
        width: 95%;
    }
}

@media only screen and (max-width: 1100px) {
    .logoMaintext {
        font-size: 20px;
    }

    .logoNav {
        max-width: 90px;
    }
}

@media only screen and (max-width: 1000px) {
    .navAccBox {
        width: 120px;
    }

    .secOneSub2 h2 {
        font-size: 58px;
    }

    .logoNav {
        max-width: 80px;
    }

    .navAccBox {
        width: 100px;
    }
}

@media only screen and (max-width: 800px) {
    .navAccBox {
        width: 100px;
    }

    .logoMaintext {
        font-size: 20px;
        position: relative;
        top: -20px;
    }

    .logoNav {
        max-width: 70px;
    }

    .navBar {
        width: 98%;
    }

    .navAccBox {
        width: 80px;
    }

    .logoMaintext {
        font-size: 18px;
        top: 0px;
    }
}

@media only screen and (max-width:750px) {
    .navAccBox {
        display: none;
    }

    .navBar {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .secOneSub2 h2 {
        font-size: 51px;
    }
}

@media only screen and (max-width: 650px) {
    .secOneSub {
        width: 100%;
        position: absolute;
        z-index: inherit;
    }

    .secOneSub2 {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .navBar {
        justify-content: space-between;
    }

    .navAccBox {
        padding-left: 20px;
        padding-right: 20px;
    }

    .searchBox {
        display: none;
    }

    .topBarTwo {
        display: none;
    }

    .topBar {
        align-items: center;
        justify-content: center;
    }
}

/* Navbar Mobile */

.navBarMobile {
    background-color: white;
    width: 95%;
    max-width: 1222px;
    height: auto;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 15px;
}

.logoNavMobile {
    max-width: 100px;
    height: auto;
    cursor: pointer;
}

.logoMaintextMobile {
    color: rgb(49, 49, 49);
    font-size: 22px;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.menuNav {
    font-size: 27px;
    position: absolute !important;
    top: 76px;
    left: 30px;
    cursor: pointer;
}

@media only screen and (min-width:750px) {
    .navBarMobile {
        display: none;
    }
}

@media only screen and (max-width:500px) {
    .logoMaintextMobile {
        font-size: 20px;
    }
}

@media only screen and (max-width:460px) {
    .logoMaintextMobile {
        font-size: 18px;
        margin-right: 10px;
    }

    .navBarMobile {
        justify-content: flex-end;
    }

    .logoNavMob {
        width: 70px;
    }

    .menuNav {
        left: 20px;
    }
}

/* Sec2 */

.sec2Home {
    width: 100vw;
    height: auto;
    min-height: 200px;
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 70px; */
    font-family: "Poppins", sans-serif;
}

.sec2Home h2 {
    font-size: 51px;
    padding: 50px;
    background-color: #ffd36c;
    /* background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%); */
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 75px;
    border: 2px solid white;
}

/* Section 3 */

.sec3Home {
    width: 100vw;
    height: auto;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    /* background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%); */
}

.sec3Sub {
    margin-top: 50px;
    width: 100%;
    height: auto;
    min-height: 200px;
    max-width: 1222px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: white; */
    border-radius: 20px;
}

.sec3Sub h2 {
    font-size: 51px;
    margin: 20px auto;
    font-weight: 700;
}

.categoryDivs {
    width: 100%;
    height: auto;
    min-height: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    margin-bottom: 10px;
}

.categoryDiv {
    width: 18%;
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border-radius: 50%; */
    border-radius: 15px;
    cursor: pointer;
    transition: 1s;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid rgb(255, 255, 255);
}

.categoryDiv:hover {
    transform: scale(1.05);
}

.imgCategory {
    width: 100px;
    height: 100px;
    background-color: rgb(45, 45, 45);
    border-radius: 50%;
}

.categoryImgs {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
}

.categoryDiv p {
    /* margin: 10px auto; */
    text-align: center;
    position: relative;
    top: -7px;
    font-weight: 700;
}

/* Section 2 */

.section2Main {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.section2 {
    width: 100%;
    max-width: 1222px;
}

.section2 h2 {
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Poppins", sans-serif;
    font-size: 51px;
    color: rgb(20, 20, 20);
    margin-top: 30px;
    margin-bottom: 50px;
}

.featuredProducts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 20px; */
}

.productsDiv {
    width: 22%;
    height: auto;
    /* min-width: 200px; */
    min-height: 320px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    transition: 0.5s;
    cursor: pointer;
}

.productsDiv:hover {
    transform: scale(1.05);
}

.bgImgProducts {
    width: 100%;
    height: auto;
    min-height: 200px;
    /* background-image: url(../components/images/products/Aspirating-Syringe.jpg); */
    /* background-image: url("http://www.birds.com/wp-content/uploads/home/bird4.jpg") no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 10px;
}

.discountProducts {
    border: none;
    padding: 7px;
    margin: 10px 0px;
    background: rgb(243, 163, 50);
    /* border-radius: 5px; */
    color: white;
}

.toolsProducts {
    float: right;
    display: flex;
    width: 20px;
    height: auto;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    display: none;
    transition: 1s;
}

.productsDiv:hover .toolsProducts {
    transition: 1s;
    display: flex;
}

.toolsProducts i {
    margin: 10px;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
    color: rgb(43, 43, 43);
}

.detailsProducts {
    width: 100%;
    height: 90px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    word-wrap: break-word;
}

.detailsProducts span {
    width: 90%;
}

.moreBtn {
    width: auto;
    width: 200px;
    padding: 20px;
    margin: 25px auto;
    margin-bottom: 50px;
    display: block;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    border: 3px solid rgb(243, 163, 50) !important;
    background-color: white !important;
    color: rgb(243, 163, 50) !important;
    text-decoration: none;
    text-align: center;
    font-weight: 800;
}

.moreBtn:hover {
    text-decoration: none;
}

@media only screen and (max-width: 1250px) {
    .featuredProducts {
        padding: 0px;
    }

    .productsDiv {
        width: 30vw;
    }
}

@media only screen and (max-width: 950px) {
    .productsDiv {
        width: 40vw;
    }
}

@media only screen and (max-width: 700px) {
    .section2 h2 {
        font-size: 40px;
    }

    .productsDiv {
        width: 45vw;
        min-height: 320px;
    }
}

@media only screen and (max-width: 450px) {

    .productsDiv {
        width: 80vw;
        min-height: 320px;
    }
}

/* Track your order */
.orderTrackMain {
    width: 100vw;
    height: auto;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.orderTrackDiv {
    width: 100%;
    max-width: 1200px;
    height: auto;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reviewHeading {
    font-size: 41px;
    margin-bottom: 50px;
    font-weight: 700;
}

.orderInputs {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderInputs input {
    width: 250px;
    height: auto;
    margin: 20px 10px;
    padding: 10px;
    font-size: 17px;
    border: none;
    outline: none;
    border-bottom: 2px solid hsla(55, 90%, 67%, 1);
    border-radius: 5px;
}

.trackBtn {
    width: 120px;
    height: auto;
    padding: 15px 10px;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%);
}

/* Attributes &  testimonials*/

.section3Main {
    width: 100vw;
    height: auto;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.attributesDiv {
    width: 95%;
    max-width: 1200px;
    height: auto;
    min-height: 40vh;
    background-color: rgb(218, 218, 218);
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px 20px 0px 0px;
}

.attributesSub {
    width: 100%;
    height: auto;
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.attributesDiv h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-top: 20px;
    position: relative;
    bottom: -20px;
}

.attDiv {
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.attDiv i {
    font-size: 40px;
    color: #f6ff00;
}

.attDiv img {
    width: 150px;
    position: relative;
    bottom: -7px;
}

.attDiv p {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
}

.testimonialsDiv {
    width: 95%;
    max-width: 1200px;
    height: auto;
    min-height: 40vh;
    background-color: rgb(31, 31, 31);
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 0px 0px 20px 20px;
}

.testimonialsDiv h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-top: 30px;
}

.testiSub {
    width: 80%;
    height: auto;
    min-height: 150px;
    margin: 0 auto;
}

.testiSub p {
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
}

.quoteStart {
    font-size: 25px;
    color: #f6ff00;
    float: left;
    position: relative;
    top: -20px;
}

.quoteEnd {
    font-size: 25px;
    color: #f6ff00;
    float: right;
    position: relative;
    top: -30px;
}

.control-dots li {
    margin-top: 50px !important;
}

.testName {
    position: relative;
    bottom: -20px;
    display: flex;
    flex-direction: column;
}

/* Attributes &  testimonials ends*/