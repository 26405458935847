.dashMain {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
}

.dashMainSlide {
    width: 25%;
    max-width: 300px;
    height: 100vh;
    position: fixed;
    background-color: #1d2327;
    color: rgb(235, 235, 235);
}

.mainImg {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 2px solid white;
}

.mainImg img {
    width: 100px;
    height: auto;
    position: relative;
}

.mainImg span {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    position: relative;
    top: 2px;
    left: -10px;
    text-transform: uppercase;
}

.mainServices {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 30px;
}

.mainServicesspan {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    color: white;
}

.mainServicesspan:hover {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
    color: #e5a825;
    border-left: 7px solid #e5a825;
}

.mainServicesspan i {
    margin-right: 20px;
    text-align: center;
}

.mainServicesspan span {
    position: absolute;
    left: 60px;
}

.dashMainCntxt {
    width: 80%;
    height: auto;
    min-height: 100vh;
    background: #ececec;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.dashMainCntxt h2 {
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    margin-top: 50px;
    /* margin-left: 50px; */
}

/* Services */
.selectedService {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
    color: #e5a825;
    border-left: 7px solid #e5a825;
}

/* Products */

.dashProductsMain {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashProducts {
    width: 100%;
    height: auto;
    max-width: 1050px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    left: 40px;
}

.dashProducts h1 {
    /* font-size: 21px; */
    font-family: "Poppins", sans-serif;
}

.dashProductsInput {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.dashProductsInput input {
    width: 600px;
    height: auto;
    padding: 10px;
    font-size: 18px;
    margin: 15px;
    border-radius: 10px;
    outline: none;
    margin-left: 30px;
}

.dashInput1 {
    width: 100%;
    height: auto;
    display: flex;
}

.stockDiv {
    width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid black;
    padding: 10px;
    font-size: 18px;
    margin: 15px;
    border-radius: 10px;
    position: relative;
    left: 15px;
}

.stockDiv span {
    width: 50%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stockDivSelected {
    background-color: #e5a825;
}

.dashProductsSubmit {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashProductsSubmit button {
    width: 150px;
    height: 50px;
    font-size: 19px;
    margin-bottom: 50px;
    border-radius: 12px;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
}

/* .productCatDiv {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.catBtn {
    width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid black;
    padding: 10px;
    font-size: 18px;
    margin: 15px;
    border-radius: 10px;
}

.productCatDiv div {
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    font-size: 18px;
    margin: 15px;
    border-radius: 10px;
    position: absolute;
    top: 70px;
    background-color: white;
    z-index: 5;
}

.productCatDiv div span {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 5px 0px;
    cursor: pointer;
}

.productCatDiv div span:hover {
    background-color: #e5a825;
    color: white;
}

/* category */

.dashCatMain {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashCatSub {
    width: 100%;
    max-width: 900px;
    height: auto;
    min-height: 90vh;
}

.dashCatSubh3 {
    margin-left: 50px !important;
    font-size: 31px;
    font-family: "Poppins", sans-serif;
}

.catNames {
    width: 470px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 20px;
    background-color: white;
    position: relative;
    left: 50px;
    border-radius: 12px;
}

.catNames div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: -20px;
    padding: 5px;
}

.catNames div span {
    font-size: 21px;
    font-weight: 600;
    margin-left: 50px !important;
}

.catTrash {
    position: relative;
    cursor: pointer;
}

.addNewCatDiv {
    position: absolute;
    top: 160px;
    width: 300px;
    height: 200px;
    right: 15vw;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.addNewCatDiv button {
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px;
}

.addNewCatDiv input {
    width: 200px;
    font-size: 18px;
}

.addNewCat {
    position: absolute;
    top: 50px;
    width: 100px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    right: 20vw;
    border-radius: 12px;
    cursor: pointer;
}

/* Profile */

.profileInfoDiv {
    width: 500px;
    height: auto;
    min-height: 300px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    right: -35px;
    font-family: "Montserrat", sans-serif !important;
}

.profileInfoDiv input {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    font-family: "Montserrat", sans-serif !important;
    margin-top: 40px;
}

.profileInfoDiv button {
    width: 200px;
    height: 60px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif !important;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    color: white;
    background: #e5a825;
    position: relative;
    top: 80px;
}

.productsPageSubDiv {
    height: 320px;
}

.productColorSub{
    display: flex;
}

.productColorSub button {
    width: 45px;
    height: 50px;
    background-color: #e5a825;
    border-radius: 0px 10px 10px 0px;
    position: relative;
    left: -22px;
    top: 15px;
    font-weight: 1000;
    cursor: pointer;
}

.addedInputs{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 440px;
    position: relative;
    left: 45px;
}