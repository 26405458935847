.footerMain {
    width: 100vw;
    height: auto;
    min-height: 300px;
    background-color: rgb(39, 39, 39);
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerDiv {
    width: 100%;
    max-width: 1222px;
    height: auto;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    overflow-x: hidden;
}

.footerSub1 {
    width: 30%;
    height: auto;
    min-height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.footerImgntagline {
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerLogo {
    width: 150px;
    height: auto;
}

.footerImgntagline span {
    padding-top: 5px;
    margin-left: 20px;
}

.footerContact {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    /* margin-top: 10px; */
    position: relative;
    top: 30px;
}

.footerContact span {
    display: flex;
}

.footerContact span i {
    position: relative;
    top: 6px;
    margin-right: 10px;
}

.footerContact span {
    padding: 7px 5px;
}

.footerSub2 {
    width: 70%;
    height: auto;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.footerSub2Sub {
    width: 100%;
    height: 50%;
    min-height: 150px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 50px;
}

.footerLinks {
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.footerLinks p {
    font-weight: 600;
    text-transform: uppercase;
}

.footerLinks div {
    width: 100%;
    height: auto;
    margin-left: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.footerLinks div span {
    margin-top: 10px !important;
}

.footerLinksNav {
    color: rgb(185, 185, 185);
    /* box-sizing: border-box; */
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
}

.footerLinksNav:hover {
    color: rgb(255, 255, 255);
}

.footerSub2Sec {
    width: 100%;
    height: 50%;
    min-height: 150px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 25%;
    position: relative;
    top: 20px;
}

.socialLinks p {
    font-weight: 600;
    text-transform: uppercase;
}

.socialLinks a {
    margin-right: 15px;
    color: white;
    transition: 1s;
}

.socialLinks a:hover {
    color: rgb(60, 60, 193);
}

@media only screen and (max-width: 1000px) {
    .footerSub1 {
        width: 75%;
        padding-left: 20px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .footerSub2 {
        width: 100%;
    }

    .footerSub2Sub {
        flex-direction: row;
    }

    .footerDiv {
        flex-direction: column;
    }

    .footerImgntagline {
        align-items: center;
        justify-content: flex-start;
    }

}

@media only screen and (max-width: 500px) {
    .footerSub2Sub {
        width: 100%;
        flex-direction: column;
        margin-left: 0px !important;
    }
    .footerLinks{
        width: 100%;
    }
    .footerLinks div{
        margin-left: 25%;
    }
}