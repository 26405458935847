.shop {
    width: 100vw;
    height: auto;
    position: relative;
    /* padding-top: 50px; */
    background: linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);
}

.ShopMainmost {
    width: 100vw;
    height: auto;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shopDiv {
    width: 100%;
    height: auto;
    min-height: 90vh;
    max-width: 1222px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
}

.shopSub1 {
    width: 48%;
    height: auto;
    background-color: white;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border-radius: 12px;
    border: 2px solid black;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px; */
}

.shopSub1 img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.shopSub2 {
    width: 48%;
    height: auto;
    min-height: 450px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-evenly; */
    border-radius: 12px;
}

.reviewStarsShop {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 25px;
    margin-top: 20px;
}

.reviewStarsShop i {
    margin: auto 1px;
    color: gold;
    font-size: 21px;
}

.reviewStarsShop span {
    margin-left: 5px;
}

.shopSub2Sub1 {
    width: 100%;
    margin-left: 25px;
}

.shopSub2Sub1 h2 {
    position: relative;
    top: 10px;
}

.shopSub2Sub2 {
    margin-left: 25px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;
}

.shopSub2Sub2 p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
}

.shopSub2Sub2 p span {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
}

.quantityShop {
    position: absolute;
    top: 30%;
    right: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat", sans-serif;
}

.quantityShop span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantityShop span i {
    background: linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);
    padding: 6px;
    cursor: pointer;
}

.quantityShop span span {
    font-size: 18px;
}

.shopPrice {
    margin-left: 5px;
    position: relative;
    top: 10px;
}

.shopBtnsDv {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    font-family: "Montserrat", sans-serif;
}

.shopBtnsDv button {
    width: 150px;
    height: 50px;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 10px;
    border-radius: 8px;
    outline: none;
    border: none;
    background: linear-gradient(90deg, hsla(55, 90%, 67%, 1) 0%, hsla(56, 92%, 76%, 1) 0%, hsla(29, 100%, 77%, 1) 100%);
    margin-left: 25px;
}

/* Attributes */

.attributeShopMainMost {
    width: 100vw;
    height: auto;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    /* border-bottom: 1px solid black; */
}

.attributeShopDiv {
    width: 90%;
    height: auto;
    min-height: 150px;
    max-width: 1222px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-wrap: wrap;
    margin: 50px auto;
}

/* guest reviews */

.reviewSubShop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.reviewStarsShop {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.reviewNameShop {
    position: relative;
    left: -80px;
    bottom: 10px;
    font-size: 42px;
}

/* post review */

.shopReview {
    width: 100%;
    height: auto;
    max-width: 1222px;
    display: flex;
}

.shopReview1 {
    width: 50%;
    height: auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
}

.shopReview1 textarea {
    width: 90%;
    height: 270px;
    font-size: 20px;
    padding: 15px;
    border-radius: 10px;
    /* outline: none; */
    border: none;
    font-family: sans-serif;
}

.shopReview2 {
    width: 50%;
    height: auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
}

.shopReview2 input {
    width: 60%;
    font-size: 20px;
    padding: 15px;
    margin-bottom: 65px;
    border-radius: 10px;
    /* outline: none; */
    border: none;
}

.shopReviewStars {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 30px;
}

.shopReviewStars i {
    font-size: 30px;
    color: rgb(218, 180, 14);
    margin-right: 10px;
}

@media only screen and (max-width:1440px) {

    .quantityShop {
        right: 10%;
    }
}

@media only screen and (max-width:1040px) {

    .quantityShop {
        right: 50px;
    }
}

@media only screen and (max-width:720px) {

    .shopDiv {
        flex-direction: column;
    }

    .shopSub1 {
        width: 90%;
        margin-top: 20px;
    }

    .shopSub2 {
        width: 90%;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .quantityShop {
        right: 70px;
    }

}