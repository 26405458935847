/* Tables for form & customers*/

/* .dashtable {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
 */

.dashtable {
    width: 95%;
    border: 1px solid rgb(192, 192, 192);
    margin-top: 25px;
    margin-bottom: 25px;
    overflow-x: scroll !important;
}

table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    border: 1px solid rgb(192, 192, 192);
    width: 100%;
}

tbody {
    width: 100%;
    height: auto;
    word-wrap: break-word;
}

th,
td {
    text-align: left;
    padding: 8px;
}

tr {
    background-color: #ffffff
}

tr:nth-child(odd) {
    background-color: #f2f2f2
}

/* td:nth-child(even) {
    color: #2271b1;
} */

th {
    background-color: #ffffff;
    color: #e5a825;
    font-weight: 400;
}

/* Profile */

#thSelect {
    width: 10px;
    height: auto;
}

#thName {
    width: 20%;
}

#thEmail {
    width: 20%;
    word-wrap: break-word;
}

#thPhone {
    width: 20%;
}

#thMsg {
    width: 100px;
    height: 20px;
    overflow: scroll;
    text-align: center;
}

#thNameTD {
    width: 20%;
}

#thEmailTD {
    width: 20%;
    word-wrap: break-word;
}

#thPhoneTD {
    width: 20%;
}

#thMsgTD {
    width: 100px;
    text-align: center;
}

/* Customers */
#custthName {
    width: 20%;
}

#custthEmail {
    width: 20%;
    word-wrap: break-word;
}

#custthPhone {
    width: 20%;
}

#custthMsg {
    width: 40%;
    height: 20px;
    overflow: scroll;
}

#custthAddress {
    width: 150PX;
    height: 20px;
    overflow: scroll;
    text-align: center;
}

#custthDrreg {
    width: 20%;
    height: 20px;
    overflow: scroll;
    text-align: center;
}

#custtPass {
    width: 20%;
    height: 20px;
    overflow: scroll;
}

#customerNameTD {
    width: 20%;
}

#customerPhoneTD {
    width: 20%;
}

#customerEmailTD {
    width: 20%;
    word-wrap: break-word;
}

#customerAddTD {
    width: 150px;
    text-align: center;
}

#customerPassTD {
    width: 20%;
}

#customerDregTD {
    width: 20%;
    text-align: center;
}

.msgDiv {
    width: 82vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.726);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 2;
}

.msgDivSub {
    width: 400px;
    height: auto;
    min-height: 200px;
    color: black;
    background-color: white;
    border-radius: 12px;
    font-size: 18px;
    padding: 10px;
    font-family: "Poppins", sans-serif;
}

#thMsgTD:active+#msgDiv {
    display: flex;
}

/* Pagination */
.paginationDiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    position: fixed;
    z-index: 1;
    bottom: 1px;
    left: 200px;
}

.paginationDiv a {
    color: #e5a825;
    background-color: white;
    padding: 5px 10px;
    font-size: 17px;
    border-radius: 5px;
    border: 1px solid #e5a825;
    margin: auto 10px;
    font-family: "Poppins", sans-serif;
}

.paginationDiv span {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
}

/* Orders */


.msgDivOrder {
    width: 82vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.726);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    /* display: none; */
}

.msgDivSubOrder {
    width: 80%;
    height: auto;
    min-height: 80vh;
    color: black;
    background-color: white;
    border-radius: 12px;
    font-size: 18px;
    padding: 10px;
    font-family: "Poppins", sans-serif;
}

.msgDivDetails {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.shippingDetails {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.billingDetails {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
}

/* Orders */

#thNameTDOrders {
    width: 30%;
}